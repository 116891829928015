<template>
  <v-navigation-drawer
    v-if="!isLoading"
    :value="isSidebarDataPaysActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    width="350"
    app
    @input="val => $emit('update:is-sidebar-data-pays-active', val)"
  >
    <v-card height="100%">
      <div class="drawer-header d-flex align-center">
        <h4 class="font-weight-semibold text-base">
          {{ $t('lbl.waysMethod') }}
        </h4>
        <v-spacer></v-spacer>
        <v-btn
          icon
          small
          @click="$emit('update:is-sidebar-data-pays-active', false)"
        >
          <v-icon size="22">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-card-text class="pt-5">
        <v-form>
          <v-row>
            <v-col
              cols="12"
              md="12"
              class="ml-2"
            >
              <strong>
                {{
                  typePay === 'transfer'
                    ? $t('lbl.transferBank')
                    : typePay === 'credit'
                      ? $t('lbl.credit')
                      : typePay === 'payCard'
                        ? $t('lbl.payCard')
                        : typePay === 'linkPay'
                          ? $t('lbl.linkPay')
                          : typePay === 'checkBank'
                            ? $t('lbl.checkBank')
                            : typePay === 'cash'
                              ? $t('lbl.cash')
                              : ''
                }}
              </strong>
            </v-col>
            <v-col
              cols="12"
              md="12"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="ma-2"
                    :outlined="typePay === 'transfer'"
                    small
                    fab
                    :color="typePay === 'transfer' ? 'primary' : 'white'"
                    :style="typePay !== 'transfer' ? 'color: gray' : ''"
                    v-bind="attrs"
                    v-on="on"
                    @click="payItems('transfer')"
                  >
                    <v-icon>mdi-bank-transfer</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('lbl.transferBank') }}</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="ma-1"
                    :outlined="typePay === 'credit'"
                    small
                    fab
                    :disabled="saldFavor + creditDisp < montoAll"
                    :color="typePay === 'credit' ? 'primary' : 'white'"
                    :style="typePay !== 'credit' ? 'color: gray' : ''"
                    v-bind="attrs"
                    v-on="on"
                    @click="payItems('credit')"
                  >
                    <v-icon>mdi-wallet-outline</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('lbl.credit') }}</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="ma-1"
                    :outlined="typePay === 'payCard'"
                    small
                    fab
                    :color="typePay === 'payCard' ? 'primary' : 'white'"
                    :style="typePay !== 'payCard' ? 'color: gray' : ''"
                    v-bind="attrs"
                    :disabled="methodsPays.length === 0"
                    v-on="on"
                    @click="payItems('payCard')"
                  >
                    <v-icon>mdi-credit-card-outline</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('lbl.payCard') }}</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="ma-1"
                    :outlined="typePay === 'linkPay'"
                    small
                    fab
                    :color="typePay === 'linkPay' ? 'primary' : 'white'"
                    :style="typePay !== 'linkPay' ? 'color: gray' : ''"
                    v-bind="attrs"
                    disabled
                    v-on="on"
                  >
                    <v-icon>mdi-credit-card-clock-outline</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('lbl.linkPay') }}</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="ma-1"
                    :outlined="typePay === 'checkBank'"
                    small
                    fab
                    :color="typePay === 'checkBank' ? 'primary' : 'white'"
                    :style="typePay !== 'checkBank' ? 'color: gray' : ''"
                    v-bind="attrs"
                    disabled
                    v-on="on"
                    @click="payItems('checkBank')"
                  >
                    <v-icon>mdi-checkbook</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('lbl.checkBank') }}</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="ma-1"
                    :outlined="typePay === 'cash'"
                    small
                    fab
                    :color="typePay === 'cash' ? 'primary' : 'white'"
                    :style="typePay !== 'cash' ? 'color: gray' : ''"
                    v-bind="attrs"
                    v-on="on"
                    @click="payItems('cash')"
                  >
                    <v-icon>mdi-cash-multiple</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('lbl.cash') }}</span>
              </v-tooltip>
            </v-col>

            <v-col
              cols="12"
              md="12"
              class="ml-2"
            >
              <!--TRANSFER   CHECK BACNK-->
              <v-row
                v-if="typePay === 'transfer' || typePay === 'checkBank'"
                no-gutters
              >
                <v-col
                  cols="12"
                  md="8"
                >
                  {{ $t('lbl.totalPay') }}
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                  class="text-right pr-2"
                >
                  {{ montoAll | currency }}
                </v-col>

                <v-col
                  cols="12"
                  md="8"
                >
                  {{ $t('register.moneda') }}
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                  class="text-right pr-2"
                >
                  USD
                </v-col>
                <v-col
                  cols="12"
                  md="12"
                  class="mt-5"
                >
                  <v-file-input
                    v-model="evidencia"
                    :label="`${$t('lbl.payEvidency')}...`"
                    accept=".pdf, image/png, image/jpeg, image/webp"
                    outlined
                    dense
                    hide-details="auto"
                    :hint="$t('lbl.formatPay', { formats: 'pdf, jpg, png, webp' })"
                    persistent-hint
                    @change="setEvidencia()"
                  ></v-file-input>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                  offset-md="8"
                  class="mt-2"
                >
                  <v-btn
                    color="primary"
                    block
                    :loading="loadingBtn"
                    @click="pay()"
                  >
                    {{ $t('btn.pay') }}
                  </v-btn>
                </v-col>
              </v-row>
              <!--CREDIT-->
              <v-row
                v-if="typePay === 'credit'"
                no-gutters
              >
                <v-col
                  cols="12"
                  md="8"
                >
                  {{ $t('lbl.totalPay') }}
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                  class="text-right pr-2"
                >
                  {{ montoAll | currency }}
                </v-col>

                <v-col
                  cols="12"
                  md="8"
                >
                  {{ $t('lbl.creditRest') }}
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                  class="text-right pr-2"
                >
                  {{ creditDisp | currency }}
                </v-col>

                <v-col
                  cols="12"
                  md="8"
                >
                  {{ $t('lbl.fondoRest') }}
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                  class="text-right pr-2"
                >
                  {{ saldFavor | currency }}
                </v-col>

                <v-col
                  cols="12"
                  md="8"
                >
                  {{ $t('register.moneda') }}
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                  class="text-right pr-2"
                >
                  USD
                </v-col>

                <v-col
                  cols="12"
                  md="12"
                  class="mt-5"
                >
                  <v-switch
                    v-model="paySaldoFavor"
                    hide-details
                    class="mt-0 ms-2"
                    :disabled="saldFavor <= 0"
                    :label="$t('lbl.payFondoRest')"
                  ></v-switch>
                </v-col>

                <v-col
                  cols="12"
                  md="4"
                  class="mt-5"
                >
                  <v-text-field
                    v-model="creditPay"
                    :label="$t('lbl.credit')"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col
                  v-if="paySaldoFavor"
                  cols="12"
                  md="4"
                  offset-md="4"
                  class="mt-5"
                >
                  <v-text-field
                    v-model="saldoFavorPay"
                    :label="$t('lbl.saldo')"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>

                <v-col
                  cols="12"
                  md="4"
                  :offset-md="paySaldoFavor ? '8' : '4'"
                  class="mt-5"
                >
                  <v-btn
                    color="primary"
                    block
                    :loading="loadingBtn"
                    @click="pay()"
                  >
                    {{ $t('btn.pay') }}
                  </v-btn>
                </v-col>
              </v-row>
              <!--PAYCARD-->
              <v-row
                v-if="typePay === 'payCard' && methodsPays.length > 0"
                no-gutters
              >
                <v-col
                  cols="12"
                  md="8"
                >
                  {{ $t('lbl.totalPay') }}
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                  class="text-right pr-2"
                >
                  {{ montoAll | currency }}
                </v-col>

                <v-col
                  cols="12"
                  md="8"
                >
                  {{ $t('register.moneda') }}
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                  class="text-right pr-2"
                >
                  USD
                </v-col>
                <v-col
                  v-if="methodsPays[0].slug !== 'redsys'"
                  cols="12"
                  md="12"
                  class="mt-5"
                >
                  <v-text-field
                    v-model="cardName"
                    :label="$t('lbl.cardName')"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="12"
                  class="mt-5"
                >
                  <v-text-field
                    v-model="cardNo"
                    :label="$t('lbl.cardNumber')"
                    outlined
                    dense
                    hide-details
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="9"
                  class="mt-5"
                >
                  <v-menu
                    ref="menu"
                    v-model="menuCardDate"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="computedFromDateFormatted"
                        :label="$t('lbl.cardExpire')"
                        prepend-icon="mdi-calendar"
                        persistent-hint
                        readonly
                        v-bind="attrs"
                        outlined
                        dense
                        hide-details
                        class="pr-2"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="cardDateExpiret"
                      type="month"
                      no-title
                      scrollable
                      :show-current="false"
                      @input="
                        menuCardDate = false
                        $refs.menu.save(cardDateExpiret)
                      "
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                  class="mt-5"
                >
                  <v-text-field
                    v-model="cardCVV"
                    label="CVV"
                    outlined
                    dense
                    hide-details
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                  offset-md="8"
                  class="mt-2"
                >
                  <v-btn
                    color="primary"
                    block
                    :loading="loadingBtn"
                    @click="pay()"
                  >
                    {{ $t('btn.pay') }}
                  </v-btn>
                </v-col>
              </v-row>
              <!--CASH-->
              <v-row
                v-if="typePay === 'cash'"
                no-gutters
              >
                <v-col
                  cols="12"
                  md="8"
                >
                  {{ $t('lbl.totalPay') }}
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                  class="text-right pr-2"
                >
                  {{ montoAll | currency }}
                </v-col>

                <v-col
                  cols="12"
                  md="8"
                >
                  {{ $t('register.moneda') }}
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                  class="text-right pr-2"
                >
                  USD
                </v-col>
                <v-col
                  cols="12"
                  md="12"
                  class="mt-5"
                >
                  <strong>{{ `${$t('lbl.cobradoPor')}:` }}</strong>
                  <v-text-field
                    v-model="password"
                    :label="`${$t('lbl.password')}...`"
                    outlined
                    dense
                    hide-details="auto"
                    :hint="userPayCash"
                    persistent-hint
                    class="mt-2"
                    type="password"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                  offset-md="8"
                  class="mt-2"
                >
                  <v-btn
                    color="primary"
                    block
                    :loading="loadingBtn"
                    @click="pay()"
                  >
                    {{ $t('btn.pay') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { mdiClose, mdiAccountCashOutline } from '@mdi/js'

export default {
  model: {
    prop: 'isSidebarDataPaysActive',
    event: 'update:is-sidebar-data-pays-active',
  },
  props: {
    isSidebarDataPaysActive: {
      type: Boolean,
      required: true,
    },
    from: {
      type: String,
      required: true,
    },
    // eslint-disable-next-line vue/require-default-prop
    user: {
      type: Object,
    },
  },
  data() {
    return {
      isLoading: true,
      icons: {
        mdiClose,
        mdiAccountCashOutline,
      },
      textRequiered: [v => !!v || 'Campo requerido'],
      typePay: null,
      evidencia: null,
      pathEvidencia: null,
      paySaldoFavor: false,
      saldoFavorPay: 0,

      creditPay: 0,
      password: null,

      cardName: null,
      cardNo: null,
      cardDateExpiret: null,
      cardCVV: null,
      menuCardDate: false,

      limitCredit: 0,
      creditRestante: 0,
      fondoRestante: 0,
      deuda: 0,
      infiniteBalance: false,
      time: null,
      timeDisponible: null,

      methodsPays: [],
    }
  },
  computed: {
    ...mapState({
      clientCarShop: state => state.app.clientCarShop,
      loadingBtn: state => state.app.loadingBtn,
      carItems: state => state.app.carItems,
    }),
    computedFromDateFormatted() {
      return this.cardDateExpiret ? this.$moment(this.cardDateExpiret).format('MM/YY') : ''
    },
    montoAll() {
      let valor = 0
      this.carItems.forEach(carItem => {
        if (carItem.check) {
          valor += carItem.data_markups.precioSuplementPayOnline
        }
      })

      return valor
    },
    userPayCash() {
      const date = this.$moment(new Date()).format('D-MMM-YY')
      const time = this.$moment(new Date()).format('hh:mm')
      const result = `${this.user.name} ${this.user.apellidos} ${date}, ${time}`

      return result
    },

    /* creditPay() {
      let valor = 0
      if (this.creditRestante > 0) {
        if (this.creditRestante >= this.montoAll) {
          valor = this.montoAll
        } else {
          valor = this.creditRestante
        }
      } else {
        valor = 0
      }

      return valor
    }, */
    creditDisp() {
      const valor = this.creditRestante

      return valor
    },
    saldFavor() {
      const valor = this.fondoRestante

      return valor
    },
  },
  created() {
    this.typePay = 'transfer'
    this.getMethodsPays()
  },
  methods: {
    ...mapMutations(['setCheckAll', 'updateItemCheck']),
    verifyCredit() {
      if (this.user.company_id !== 10000) {
        // BUSCAR EL CREDIT
        this.axios
          .get(`afiliados/${this.user.company_id}`, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            this.limitCredit = res.data.data.data.limitCredit
            this.creditRestante = res.data.data.data.creditRestante
            this.fondoRestante = res.data.data.data.fondoRestante
            this.deuda = res.data.data.data.deuda
            this.infiniteBalance = res.data.data.data.infiniteBalance
            this.time = res.data.data.data.time
            this.timeDisponible = res.data.data.data.timeDisponible

            setTimeout(() => {
              this.asignValores()
            }, 100)
          })
          .finally(() => {
            this.isLoading = false
          })
      } else {
        this.isLoading = false
      }
    },
    getMethodsPays() {
      const json = {
        type: 'payments',
      }
      this.axios
        .post('methods-pays/list', json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success) {
            const result = res.data.data.data
            result.forEach(element => {
              if (element.active) {
                this.methodsPays.push(element)
              }
            })
          }
        })
        .finally(() => {
          this.verifyCredit()
        })
    },
    asignValores() {
      if (this.creditRestante > 0) {
        if (this.creditRestante >= this.montoAll) {
          this.creditPay = this.montoAll
        } else {
          this.creditPay = this.creditRestante
        }
      }
    },
    payItems(slug) {
      this.typePay = slug
    },
    setEvidencia() {
      if (this.evidencia) {
        const formData = new FormData()
        formData.append('evidencia', this.evidencia)

        this.axios
          .post('pays-emitidos/upload-scanner', formData, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            this.pathEvidencia = res.data.data.dir
          })
      } else {
        this.pathEvidencia = null
      }
    },
    pay() {
      this.loading = true
      const json = {
        slug: this.typePay,
        evidencia: this.pathEvidencia,
        creditPay: this.creditPay,
        saldoFavorPay: this.saldoFavorPay,
        password: this.password,
        cardName: this.cardName,
        cardNo: this.cardNo,
        cardDateExpiret: this.cardDateExpiret,
        cardCVV: this.cardCVV,
      }

      let proceder = true
      let noAmonts = true
      if (this.typePay === 'credit') {
        if (this.creditPay > 0 || this.saldoFavorPay > 0) {
          if (this.creditPay + this.saldoFavorPay === this.montoAll) {
            proceder = true
          } else {
            proceder = false
            noAmonts = false
          }
        } else {
          proceder = false
        }
      } else if (this.typePay === 'transfer' || this.typePay === 'checkBank') {
        if (this.evidencia) {
          proceder = true
        } else {
          proceder = false
        }
      } else if (this.typePay === 'cash') {
        if (this.password) {
          proceder = true
        } else {
          proceder = false
        }
      } else if (this.typePay === 'payCard') {
        if (this.cardNo && this.cardDateExpiret && this.cardCVV) {
          proceder = true
        } else {
          proceder = false
        }
      }

      if (proceder) {
        this.loading = false
        if (this.from === 'pays') {
          this.$emit('confirmPay', json)
        } else if (this.from === 'convert-to-reserve-pays') {
          this.$emit('confirmConvertToReservePays', json)
        }
      } else {
        if (noAmonts) {
          this.$toast.error(this.$t('msg.datasRequiere'))
        } else {
          this.$toast.error(this.$t('msg.reviewAmounts'))
        }
        this.loading = false
      }

      /* if (proceder) {
        this.axios
          .post('pays-emitidos', json, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            // eslint-disable-next-line no-empty
            if (res.data.success === true) {
              this.$toast.success(this.$t('msg.infoSuccess'))

              // VACIAR EL ARREGLO
              this.setCheckAll(false)
              this.updateItemCheck([])
              this.$emit('update:is-sidebar-data-pays-active', false)
              this.$emit('initAll')
            }
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        if (noAmonts) {
          this.$toast.error(this.$t('msg.datasRequiere'))
        } else {
          this.$toast.error(this.$t('msg.reviewAmounts'))
        }
        this.loading = false
      } */
    },
  },
}
</script>
<style scoped>
::v-deep .v-messages__message {
  margin-top: 5px;
}
</style>
